.login-page {
  height: 100vh;
  background: #f8f9fa;

  .login-card {
    width: 900px;
    height: 500px;
    display: flex;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
		@media (max-width: 768px){
			height: 100%;
			// margin-top: 6rem;
		}
    .form-section {
      background: #f8f9fa;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem;

      h2 {
        font-size: 1.8rem;
        font-weight: bold;
        color: #333;
      }

      label {
        font-size: 1rem;
        font-weight: bold;
        color: #555;
      }

      input {
        margin-top: 0.5rem;
        height: 45px;
        font-size: 1rem;
      }

      button {
        font-size: 1rem;
        font-weight: bold;
        padding: 0.75rem;
        text-transform: uppercase;
      }
    }

    .image-section {
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}


.login-tabs {
	width: 100%;
	max-width: 100%;
	margin: 0 auto;
	text-align: center;
}
.tab-header {
	display: flex;
	justify-content: center;
	border-bottom: 2px solid $primary;
	gap: 36px;
}
.tab {
	padding: 24px 0px 10px 0px;
	cursor: pointer;
	font-weight: 600;
	transition: background-color 0.3s, color 0.3s;
	color: $primary;
}
.tab.active {
	border-bottom: 2px solid $primary-text-light;
	color: $primary-text-light;;
}
.tab:hover {
	background-color: #f9f9f9;
}
