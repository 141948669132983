.student-comments-section {
}

.student-comments-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  justify-items: center;
}

.student-card {
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 320px;
  height: 100%;
}

.student-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.student-image {
  width: 100%;
  height: 200px; 
  object-fit: cover;
}

.student-name {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  margin: 1rem 0 0.5rem;
}

.student-comment {
  font-style: italic;
  text-align: center;
  margin: 0.5rem 1rem;
  flex-grow: 1;
}

.student-achievement {
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
	color: $primary-darken;
}
