.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray-200;
  width: fit-content;
  border-radius: 20px;
  .pagination-button {
    width: 28px;
    height: 30px;
    min-width: 28px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page-number {
    cursor: pointer;
    color: $primary;
    transition: all ease-in 0.2s;
    width: 28px;
    height: 30px;
    min-width: 28px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .page-number.active {
    background-color: $primary;
    color: $white;
  }
  .page-number.active:hover {
    background-color: $primary;
    color: $white;
  }
  .page-number:hover {
    background-color: $gray-100;
    color: $primary;
  }
  .page-item {
    margin: 0 5px;

    .page-link {
      padding: 0.5rem 0.75rem;
      color: $primary;
      cursor: pointer;
      border-radius: 5px;

      &:hover {
        background-color: $gray-100;
      }

      &.active {
        background-color: $primary;
        color: white;
      }

      &.disabled {
        cursor: not-allowed;
        color: $muted;
      }
    }
  }
}
