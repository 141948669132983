body {
	overflow-x: hidden
}
.dashboard-sectionone {
	overflow: hidden !important;

  .main-image-container{
    width: 100vw; 
  height: 100vh; 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden !important;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0px;
}

.place__info-wrapper{
padding-top: 8rem;
position: relative;
@media (max-width: 768px){
padding-top: 2rem;
}
  .place-info-text{
    font-size: 24px !important;
    color:  $white;
    font-style: italic;
    font-weight: 300 !important;
		line-height: 2.1rem;
		@media (max-width: 960px){
			width: 85% !important;
			font-size: 18px !important;
			line-height: 1.8rem;

		}
  }
  .place-info-title{
    font-size: 44px;
    color:  $white;
  }
}


  .activity-date{
    background-color: $white;
    border-radius: 5px;
    width: fit-content;
  }
  .activity-name{
    font-size: 84px !important;
    color: $white;
  }

}





.dashboard-sectiontwo{
	position: relative;
	.image-section {
    .image-wrapper {
      overflow: hidden;
      border-radius: 8px; 
			margin-top: 24px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover; 
      }
    }
  }
  margin-top: 4rem;
  margin-bottom: 4rem;
	@media (max-width: 768px){
		margin-top: 0rem;
		margin-bottom: 2rem;
	}
  .detail-link-container {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 8px;
    gap: 8px;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .detail-link-container:hover .detail-arrow {
    transform: translateX(5px);
  }

  
  .detail-arrow {
    transition: transform 0.3s ease;
  }
  
  .detail-text {
    transition: color 0.3s ease;
  }
  .shop-image {
    overflow: hidden; 
  }
  
  .shop-image img {
    transition: transform 0.3s ease-in-out; 
  }
  
  .shop-image:hover img {
    transform: scale(1.1); 
  }
}
.dashboard-section-three{
  background-color: $primary;
  width: 100%;
  height: 100%;
}

.dashboard-section-four{
	h1 {
		@media (max-width: 768px){
			font-size: 26px !important;
		}
	}
	p {
		@media (max-width: 768px){
			font-size: 22px !important;
		}
	}
  .section-four--banner{
    background-image: url("../../assets/images/happy-beautiful-girl-standing-with-note-books-backpack-showing-thumb-up-smiling-min.jpg");
    height: 620px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    position: relative;
    @media (max-width: 960px){
      height: 420px;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 0px;
			@media (max-width: 768px){
				border-radius: 0px;
			}
    }
  }
}


.comments-slider {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 0;

  .slider-title {
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .slider-wrapper {
   
    gap: 1.5rem;
  }

  .comment-card {
    background: #ffffff;
    border: 1px solid #e9ecef;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease-in-out;

    .card-content {
      .message {
        font-size: 1rem;
        font-style: italic;
        margin-bottom: 1rem;
      }

      .name {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      .title {
        font-size: 1rem;
        color: #6c757d;
      }
    }
  }

  .dots-container {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    gap: 0.5rem;

    .dot {
      width: 12px;
      height: 12px;
      background: #ccc;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      transition: background 0.3s ease;

      &.active {
        background: #007bff;
      }

      &:hover {
        background: #0056b3;
      }
    }
  }
}
