.profile {
	.profile-tab{
		border: 1px solid $primary;
		border-bottom: 0px;
		padding: 12px 24px;
		border-radius: 0px;
		font-weight: 500;
		font-size: 16px;
		&:first-child {
			border-radius: 0.375rem 0.375rem 0px 0px;
		}
		&:last-child {
			border-radius: 0px 0px 0.375rem 0.375rem;
			border-bottom: 1px solid $primary;

		}
	
	
	}
	.profile-tab.active {
		background-color: $primary;
		color: $white;
		font-weight: 500;
		font-size: 16px;
	}
	.tabs {
		@media (max-width: 960px){
			margin-bottom: 12px;
		}
	}

}	
