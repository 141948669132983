/* Varsayılan `table-row` stili */
.table-row {
  transition: background-color 0.3s ease;
  width: fit-content;
	button:disabled{
		opacity: 0.3 ;
	}
}

.table-row td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table-row td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table-row.expanded {
  background-color: #ececec; 
}

.table-row .expanded td:first-child {
  border-bottom-left-radius: 0 !important;
}

.table-row .expanded td:last-child {
  border-bottom-right-radius: 0 !important;
}
.table-wrapper {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 1.5rem;
  -ms-overflow-style: none;
  scrollbar-width: 0;
  overflow-y: auto;
  min-height: 100%;
//  max-height: calc(100vh - 29.613rem);
  height: calc(100vh - 29.613rem);
  position: relative;
  flex-grow: 1;
  overflow-y: scroll;

	.table-detail,
	.table-detail td,
	.table-detail-wrapper {
		background-color: #ffff !important;
		transition: none !important;
	}
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    border-spacing: 0;

    thead {
			z-index: 10;
      position: sticky;
      background-color: $white;
      top: 0;
      th {
        color: $dark;
      }
    }

    tr {
      transition: background-color 0.3s ease;
      width: fit-content;
      border-radius: 10px;
      &:hover {
        background-color: #ececec;
      }

      td:not(.expanded):first-child {
        border-top-left-radius: 10px;
      }

      td:not(.expanded):last-child {
        border-top-right-radius: 10px;
      }

      td:not(.expanded):first-child {
        border-bottom-left-radius: 10px;
      }

      td:not(.expanded):last-child {
        border-bottom-right-radius: 10px;
      }
    }

    th,
    td {
      padding: 0.50rem 1rem;
      vertical-align: middle;
    }

    th {
      // background-color: rgba(0, 0, 0, 0.038);
			background-color: $black;
      font-weight: 600;
      cursor: pointer;
      white-space: nowrap;
      font-size: 14px;
      padding: 1rem 1rem;
			color: #ffff !important;

      &:first-child {
        border-radius: 10px 0px 0px 10px;
      }

      &:last-child {
        border-radius: 0px 10px 10px 0px;
      }

      &.sortable {
        &:hover {
          background-color: #e9ecef;
        }
      }
    }

    td {
      white-space: nowrap;
      word-wrap: break-word;
    }

    @media screen and (max-width: 768px) {
      th,
      td {
        padding: 0.5rem 0.75rem;
      }
    }

    tbody tr:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

.table-wrapper::-webkit-scrollbar {
  display: none;
}

.table-search--wrapper {
  position: relative;
}

.table-search--container {
  display: flex;
  align-items: center;
  position: relative;
  width: 40px;
  transition: width 0.3s ease;
  position: absolute;
  top: -18px;
  height: 40px;
}

.table-search--container.expanded {
  width: 200px;
}

.table-search--input {
  width: 100%;
  padding: 0px 40px 0px 10px;
  height: 40px !important;
  border: 1px solid #ccc;
  border-radius: 20px;
  opacity: 1;
  z-index: 10;
  outline: none;
}

.search-icon {
  pointer-events: none;
  z-index: 1;
}

.detail-row {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s;
  opacity: 0;
}

.detail-row.expanded {
  transition: all 0.5s;
  max-height: 500px;
  opacity: 1;
}

.detail-content {
  padding: 1rem;
  border-radius: 20px;
}

.detail-col-bg {
  td {
    text-wrap: wrap !important;
  }
}


.striped-row {
  border-bottom: 1px solid #ccc;
}

.pagination-controls{
	button {
		padding: 2px 10px !important;
		font-size: 14px !important;

	}
	.arrow-left{
		border-radius: 5px 0px 0px 5px !important;

	}
	.page-btn{
		border-radius: 0px !important;
	}
	.arrow-right{
		border-radius: 0px 5px 5px 0px !important;
	}
}