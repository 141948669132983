.contactus-page {
  padding: 2rem 0;

  .page-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
  }

  .page-subtitle {
    font-size: 1.2rem;
    color: #666;
    margin-top: 0.5rem;
  }

  .contact-info {
    .info-title {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
    }

    .info-list {
      list-style: none;
      padding: 0;

      li {
        font-size: 1rem;
        color: #333;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;

        i {
          margin-right: 0.5rem;
          font-size: 1.2rem;
          color: #007bff;
        }
      }
    }

    .map-container {
      margin-top: 1.5rem;
      iframe {
        border-radius: 8px;
        border: 1px solid #ddd;
      }
    }
  }

  .form-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  button {
    font-size: 1rem;
    font-weight: bold;
    padding: 0.75rem 1rem;
    text-transform: uppercase;
  }
}
