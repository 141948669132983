.aboutus-page {
  padding: 2rem 0;

  .page-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
  }

  .page-subtitle {
    font-size: 1.2rem;
    color: #666;
    margin-top: 0.5rem;
  }

  .section-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
  }

  .team-section {
    .team-img {
      width: 150px;
			min-width: 150px;
			min-height: 150px;
      height: 150px;
      object-fit: cover;
      border: 3px solid #6ACA07;
    }

    .team-name {
      font-size: 1.2rem;
      font-weight: bold;
      color: #333;
    }

    .team-title {
      font-size: 1rem;
      color: #666;
    }
  }

  .cta-section {
    margin-top: 2rem;

    .cta-title {
      font-size: 1.8rem;
      font-weight: bold;
      color: #333;
      margin-bottom: 1rem;
    }

    .cta-text {
      font-size: 1rem;
      color: #666;
      margin-bottom: 1.5rem;
    }

    button {
      font-size: 1rem;
      font-weight: bold;
      padding: 0.75rem 1.5rem;
      text-transform: uppercase;
    }
  }
}
