//
// menu.scss
//
#topnav-auth {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  z-index: 999;

  .dropdown {
    cursor: pointer;

    .dropdown-menu {
      min-width: 6rem;
      left: -30px !important;
    }
  }
}
.navbar-scrolled {
  background-color: rgba(130, 200, 229, 0.3) !important;
  backdrop-filter: blur(6px) !important;
  transition: background-color 0.3s ease !important;
}
#topnav {
  z-index: 1001;
  transition: all 0.5s ease;
  padding: 0px 0px;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
	background-color: $primary;

  .navbar-brand {
    position: relative;
    top: 15px;
  }

  .topbar-heart {
    &:hover {
      color: $red;
      transition: 0.2s;
    }
  }

  .navbar-nav {
    .nav-link {
      &.active {
        border-bottom: 2px solid $primary-darken;
      }
      &:hover {
        color: $primary-darken !important;
      }
    }
  }

  .navbar-toggler {
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }


  .topbar-right {
    @media (max-width: 600px) {
      margin-top: 1.5rem !important;
    }

    .dropdown-toggle {
      &::after {
        display: none;
      }
    }
  }

  @media (max-width: 1500px) {
    #topnav {
      padding: 18px 0px;
    }

   
    .navbar-nav {
      .nav-link {
        font-size: 14px !important;
        padding-right: 0.6rem !important;
        padding-left: 0.6rem !important;
      }
    }
  }

  @media (max-width: 1300px) {
    // .navbar-brand {
    //   img {
    //     width: 50px;
    //     height: 74px
    //   }
    // }

    .navbar-nav {
      .nav-link {
        font-size: 12px !important;
      }
    }
  }

  .navbar-mobile {
    transition: transform 0.3s ease-in-out;
    transform: translateX(0%);
  }

  .navbar-mobile.closed {
    transform: translateX(-100%);
  }

  .mobile-nav-item {
    &:hover {
      color: $primary !important;
    }
  }

  .navbar-mobile {
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: rgba(130, 200, 229, 0.8);
    backdrop-filter: blur(20px);
    z-index: 1020;

  }

  @media (min-width: 1200px) {
    .navbar-mobile {
      display: none !important;
    }
  }

  @media (max-width: 1199.95px) {
    .navbar-web {
      display: none !important;
    }

    .navbar-toggler {
      margin-left: auto;
    }
  }

  @media (max-width: 600px) {
    #topnav {
      padding: 10px 0px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;

    }

    .navbar-brand {

      img {
        width: 180px;
        height: 60px;
      }
    }

    .topbar-right {
      width: 100%;
      justify-content: flex-end;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem !important;
    }
  }

}

.american-flag-color {
  background-color: linear-gradient(90deg, #041e42 20%, #9b1c2c 100%) !important;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content-language {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  min-width: 160px;

  border-radius: 20px;
  z-index: 1;

}


.dropdown-content {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  min-width: 260px;
  border-radius: 20px;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: $primary;
  color: $white;
  border-radius: 20px;

}


.topbar-search {
  .navbar-container {
    align-items: center;
    height: 100%;
    padding: 0 20px;
   
  
  }
  .search-bar {
    position: relative;
  }
  .form-search:focus {
    outline: none !important;
    border:1px solid $primary;
  }
  
  .menu {
    background-color: $white;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1;
    border-radius: 8px !important;
    max-height: 700px;
    min-height: 600px;
    overflow-y: scroll;
    margin-top: 1px;
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    box-shadow: -15px 15px 40px rgba(231, 84, 128, 0.4);
    z-index: 101;
    
  }
  
  .menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .menu li {
    padding: 10px;
  }
  
  .menu li:hover {
    background-color: #333;
  }
  
}

.social-dropdown-menu {
  .topbar-dropdown {
    .dropdown-toggle{
      background-color: transparent !important;
      border: none !important;
      font-weight: 400;
      font-size: 16;
      padding: 0 !important;
      color: #32334B;
    }

  }
  .dropdown-menu{
    width: 250px;
  }

}
.social-dropdown-menu-mobile {
  .dropdown-menu{
    border: none !important;
    background-color: transparent !important;
    height: 100%;
  }
  .topbar-dropdown {
    .dropdown-toggle{
      background-color: transparent !important;
      border: none !important;
      padding: 0 !important;
    }

  }

}

.topbar-search {
  .menu {
    .slick-track{
      width: 6080px !important;
      overflow-x: hidden;
      min-height: 200px;
    }
    .product__list-container{
      width: 100% !important;
      display: block !important;

    }
    .product-down {
      padding-top: 69px;
      @media only screen and (max-width: 991px){
        padding-top: 63px !important;
      }
      @media only screen and (max-width: 484px){
        padding-top: 50px !important;
      }


    }
    .product__image-resize--config{
      min-height: 190px !important;
    }

    .activity__search-sec{
      .product__image-resize--config{
        min-height: 165px !important;
      }
    }
  }
}
.topbar__icon-b {
  width: 50px;
}

.topbar-container-all{
  @media (max-width: 1199px){
    margin-bottom: 0.5rem;
    gap: 0.5rem;
  }
}