.news-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
	@media (max-width: 768px){
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

	}
}

.news-card {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
	position: relative;
}

.news-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.news-card-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.news-card-content {
  padding: 10px 12px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.news-card-title {
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #333;
  margin: 0 0 10px;
  text-align: start;
}

.news-card-description {
  font-size: 14px;
  color: #555;
  line-height: 1.6;
  text-align: justify;
}

.loading-text {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #666;
  padding: 20px;
}

.news-delete-btn{
	color: #ff2e1f;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.5s;
	font-size: 14px;


}
.news-edit-btn{
	color: $primary-darken;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.5s;

}


.news-detail-header {
  margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
}

.news-detail-title {
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #333;
}

.news-detail-date {
  font-size: 14px;
  color: #777;
  margin-bottom: 20px;
}

.news-detail-image-container {
  width: 400px;
	max-height: 400px;
  margin-right: 20px;
	float: left;
}

.news-detail-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.news-detail-text {
  flex: 1; /* Yazı genişliği resme bağlı olarak ayarlanır */
  font-size: 16px;
  line-height: 1.6;
  color: #444;
}

.news-detail-footer {
  margin-top: 20px;
  text-align: right;
  font-size: 14px;
  color: #555;
}

.news-category {
  font-style: italic;
}

.bread-crumbs {
  margin-bottom: 20px;
  font-size: 12px;
  color: #555;
}

.breadcrumb-link {
  color: $muted;
  text-decoration: none;
  margin-right: 5px;
}

.breadcrumb-link:hover {
  text-decoration: underline;
}

.breadcrumb-separator {
  margin-right: 5px;
  color: #777;
}

.breadcrumb-current {
  color: $primary;
	font-weight: 600;
}


.news{
	.dropdown-toggle{
		background: transparent;
    color: $primary-darken;
    border-radius: 20px;
		padding: 6px 24px !important;
		min-width: 185px !important;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&:hover{
			background-color: transparent !important;
			color: $primary-darken !important;

		}
	
	}
	.dropdown-item{
		font-weight: g00 !important;
		font-size: 14px !important;
	}
}
/* Dots container */
.slick-dots {
  bottom: 0px;
  display: flex !important;
  justify-content: center;
  gap: 10px;
	margin-bottom: 36px !important;
	@media (max-width: 960px){
		margin-bottom: 68px !important;
	}
}

.slick-dots li button{

}

/* Individual dot */
.slick-dots li {
  width: 12px !important;
  height: 12px !important;
  border: 2px solid $success-light;
	background-color: $success-light;
  border-radius: 50%;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
}

.slick-dots li button {
  opacity: 0;
  cursor: pointer;
	width: 12px !important;
	height: 12px !important;
}

.slick-dots li.slick-active {
  border-color: $success; /* Aktif noktanın rengi */
  background-color: $success; /* Aktif noktanın arka plan rengi */
}

.slick-dots li:hover {
  border-color: $success; /* Üzerine gelindiğinde nokta rengi */
}

/* Button for each dot */
.slick-dots li button:before {
  display: none; /* Varsayılan noktayı kaldır */
}

.slick-track {
	height: 450px;
	overflow: hidden;
	@media (max-width: 960px){
		height: 525px !important;
	}
	@media (max-width: 1440px){
		height: 525px !important;
	}
}
 .slick-slide {
      margin: 0 12px;
  }

.news-detail-content-wrapper{
	min-height: 500px;
}
.news-category-badge{
	background-color: $success-light;
	border-radius: 1000px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px 24px;
	width: fit-content;
	color: $success;
	font-size: 14px;
	font-weight: 500;
}


.news-slider-container {
  padding: 20px;
  background-color: #f9f9f9;
}

.news-slider-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.news-slider-card:hover {
  transform: scale(1.02);
}

.news-slider-card-image {
  width: 100%;
  height: 240px;
  object-fit: cover;
  border-radius: 8px 8px 0px 0px;
}

.news-slider-card-content {
  margin-top: 10px;
  text-align: start !important;
}

.news-slider-card-title {
  font-size: 18px !important;
  font-weight: bold !important;
  margin-bottom: 5px;
}

.news-slider-card-description {
  font-size: 14px !important;
  color: #555;
}
